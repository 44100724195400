import React from 'react';
import HeaderOne from "../common/header/HeaderOne";
import HeroOne from '../components/hero/HeroOne';
import CounterOne from '../components/counter/CounterOne';
import AboutThree from '../components/about/AboutThree';
import WhyChooseThree from '../components/why-choose-us/WhyChooseThree';
import CtaOne from '../components/cta/CtaOne';
import FaqOne from '../components/faq/FaqOne';
import CounterTwo from '../components/counter/CounterTwo';
import CtaTwo from '../components/cta/CtaTwo';
import BlogOne from '../components/blog/BlogOne';
import FooterOne from '../common/footer/FooterOne';

const HomeFour = () => {
    return (
        <>
            <HeaderOne />
            <HeroOne />
            <CounterOne />
            <AboutThree />
            <WhyChooseThree />
            <CtaOne />
            <FaqOne />
            <CounterTwo />
            <CtaTwo />
            <BlogOne />
            <FooterOne />
        </>
    )
}

export default HomeFour;